.inner-nav-container {
    width: 90%;
    margin: 0rem auto 0 auto;
    padding-top: 3rem;
    //color:#fff;
    box-sizing: border-box;

    a {
        text-decoration: none;
        color: black;
    }

    ul {
        display: flex;
        width: 70%;
        align-items: center;
        justify-content: center;
        list-style: none;
        margin: 0 auto;
        padding: 0;

        a {
            &:last-child {
                li {
                    border: none !important;
                }
            }
        }

    }

    ul li {
        font-size: 1.5rem;
        // margin: 0 0.5rem;

        padding: 0 0.7rem;

    }

    .mega-nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .nav-active {
        font-size: 1.7rem;
        font-weight: bolder;
    }

    .hamburger {
        cursor: pointer;
        //   width: 100%;
    }

    .main-logo {
        display: block;
        width: 0%;

        .mobile-search {
            display: none;

        }
    }

    .user-dropdown {
        position: absolute;
        display: none;
        flex-direction: column;
        margin: 2.5rem 0;
        width: 180px;
        right: -24px;
        align-items: center;

        ul {
            display: flex;
            flex-direction: column;
            color: rgb(202, 70, 37);
            background-color: #fff;
            margin-top: 10px;

            li {
                width: 100%;
                padding: 0.5rem;
                transition: 0.3s;
                text-align: center;

                &:hover {
                    background-color: rgb(202, 70, 37);
                    color: #fff;
                }
            }
        }
    }
}

.cart-count {
    position: relative;
    top: -80%;
    left: -5px;
}

.career-navigation-sm {
    display: none;
}

.user-container {
    position: relative;
    display: flex;
    gap: 14px;

    .user-dropdown {
        //position: absolute;
        display: none;
        flex-direction: column;
        margin: 0 0;
        width: 195px;
        //right: -80px;
        align-items: center;

        ul {
            display: flex;
            flex-direction: column;
            color: rgb(202, 70, 37);
            background-color: #fff;
            margin-top: 10px;

            // box-shadow: 0px 1px 1px 0px #000;
            li {
                width: 100%;
                padding: 0.5rem;
                transition: 0.3s;
                text-align: center;
            }
        }
    }

    //   &:hover{
    //     .user-dropdown{
    //         display: block;
    //     }
    //   }
}

#toggles {
    position: relative;

    a {
        color: #fff;

        &:last-child {
            li {
                border: none !important;
            }
        }
    }

    ul li {
        border-color: #fff;
        border-right: 1px solid #fff;

    }

    #gallery-toggle {
        position: absolute;
        right: 0%;
        color: rgb(255, 255, 255);
        width: fit-content;
    }
}

@media (max-width: "900px") {
    .common-nav-container {
        margin: 1rem auto 0 auto;

        ul {
            width: 80%;

            li {
                font-size: 1.3rem;
            }
        }

        // .main-logo {
        //     display: none;
        // }
    }
}

@media (max-width: "768px") {
    .inner-nav-container {
        overflow-x: visible;

        .user-dropdown {
            position: absolute;
            flex-direction: column;
            margin: 2.5rem 0;
            top: 18px;
            width: 180px;
            right: -15px;
            bottom: -75px;
            z-index: 9999;
            align-items: center;
        }
    }

    .main-logo {
        display: none !important;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;

        svg {
            width: 60px !important;
            height: 30px !important;
            // margin-right: -20px;
        }

        .mobile-search {
            display: block;

            // margin-left: -30px;
            svg {
                margin-right: 0px;
                width: 25px !important;
                height: 25px !important;
                transform: scale(1) !important;
            }
        }
    }

    .inner-nav-container .main-logo .mobile-search {
        display: block;
    }

    #orange {
        a {
            margin-right: -30px;
        }

        svg {
            width: 120px !important;
            height: 60px !important;
        }

        .mobile-search {
            margin-right: -30px;

            svg {
                width: 25px !important;
                height: 25px !important;
                transform: scale(1) !important;
            }
        }

        .mobile-cart {
            svg {
                width: 32px !important;
                height: 32px !important;
            }
        }
    }

    .career-navigation-lg {
        padding-top: 1rem;

        ul {
            width: 100%;
            flex-wrap: wrap;
        }
    }

    .career-navigation-sm {
        display: none;
        width: 100%;

        ul {
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    .nav-active {
        font-size: 1.3rem;
    }

    .user-container {
        display: flex;
    }
}

@media (max-width: "480px") {
    .common-nav-container {
        ul {
            li {
                font-size: 1rem;
            }
        }

        .nav-active {
            font-size: 1.2rem;
        }
    }
}

.user-container {
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
}

.wrapper_logo {
    display: flex;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 3px 8px 0 4px;
    border-radius: 30px;
    border: 1px solid;
    border-color: #CA4625;
}

.profile-container:hover .icon-rotate {
    transform: rotate(180deg);
    margin-top: -6px;
}

.namastetext {
    display: block;
    line-height: 14px;
    margin-top: -4px;
}

@media(max-width: 769px) {
    .namastetext {
        display: none;
    }

    .chevr-bd {
        display: none;
    }

    .wrapper_logo {
        padding: 2px 2px 0px 2px;
        border: none;
    }
}

.nutri_diet_nav_modify {
    display: flex;
        align-items: center;
        justify-content: center;
}

.vertical_line::before {
    content: "";                  /* Empty content for the line */
    display: inline-block;        /* Makes it behave like an element */
    width: 2px;                   /* Line thickness */
    height: 40px;                 /* Line height */
    background-color: #ccc;       /* Line color */
    margin: 0 18px;               /* Spacing around the line */
  }